<template>
  <div class="page container">
    <div class="page-title mt-5">
      <h1>登録案内メールを送信しました</h1>
    </div>
    <div class="mt-5 text-center">
      <p>ご入力いただいた以下のメールアドレスに登録案内メールを送信いたしました。</p>
      <p>{{email}}</p>
      <p>メールをご確認いただき、本文のURLより新規登録のお手続きをお願いいたします。</p>
    </div>
    <div class="p-3 mt-5 warning-box">
      <font-awesome-icon class='va-m' icon="exclamation-triangle"></font-awesome-icon>
      お手続きはメール送信日時より24時間以内にお願いいたします。
    </div>
    <div class="mt-5 text-center">
      <p>ご入力いただいたメールアドレスに登録案内メールをお送りいたします。</p>
      <j-button on-click="javascript:window.close();" variant="primary" class="mt-2" size="lg">
        画面を閉じる
      </j-button>
      <br />
      <j-button variant="light" class="mt-4" to="/initiate/mail-address">
        <font-awesome-icon class="va-m" icon="arrow-left"></font-awesome-icon>
        前へ戻る
      </j-button>
    </div>
  
  </div>
</template>

<style scoped lang="scss">
  .warning-box{
    background: #FFF0F1;
    width: 700px;
    margin: 0 auto;
  }
</style>
<script>
  
  export default {
    
    data: function(){
      return {
        email: ''
      }
    },
    mounted: function(){
      this.email = this.$route.query.email;
    }
  }
</script>